import React, { createContext, useState, useContext } from 'react';

const InboxContext = createContext();

export const useInboxContext = () => useContext(InboxContext);

export const InboxProvider = ({ children }) => {
  const [contacts] = useState([
    { id: 1, number: '+919266138445', message: 'Thank you for contacting us. ...', time: '2 hr', online: true },
    { id: 2, number: '+919731739221', message: 'Thank you for contacting us. ...', time: '4 hr', online: false },
    { id: 3, number: '+917300564715', message: 'Thank you for contacting us. ...', time: '5 hr', online: true },
    { id: 4, number: '+917906441392', message: 'Thank you for contacting us. ...', time: '5 hr', online: false },
    { id: 5, number: '+919100516805', message: 'Thank you for contacting us. ...', time: '1 day', online: false },
    { id: 6, number: '+919867438706', message: 'Thank you for contacting us. ...', time: '1 day', online: true },
  ]);

  const [selectedContact, setSelectedContact] = useState(null);
  const [searchMode, setSearchMode] = useState(false);
  const [filterMode, setFilterMode] = useState(false);
  const [selectionMode, setSelectionMode] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);

  return (
    <InboxContext.Provider value={{
      contacts,
      selectedContact,
      setSelectedContact,
      searchMode,
      setSearchMode,
      filterMode,
      setFilterMode,
      selectionMode,
      setSelectionMode,
      selectedContacts,
      setSelectedContacts
    }}>
      {children}
    </InboxContext.Provider>
  );
};