import React, {useState} from 'react';
import { Commingsoon } from '../../utils/CommonLibrary';
import { Link } from "react-router-dom";
import { validateFormNewConditionAdd, validateFormPathwayAdd } from '../../utils/validationUtils';
import { AddNewPathway, editFetchNewCondition, editNewCondition, removeNewCondition } from '../../../services/mtp/Service';
function DropDownAction({conditionId, programId, onCallBackFromChild,ifCheck}) {

    const [showEditConditionModal, setShowEditConditionModal] = useState(false);
    const [showAddPathwayModal, setShowAddPathwayModal] = useState(false);
    const [showDeleteConditionModal, setShowDeleteConditionModal] = useState(false);
    
    

    //Call back for refresh page
    const sendRefreshToParent = ()=>{
        onCallBackFromChild();
    }
    const handleClose = () =>{ setFormData({
        programId: programId,
        conditionId: conditionId,
        conditionName: '',
        conditionDescription: '',
        pathwayName:'',
    }); 
    setErrors({
        programId: programId,
        conditionId: conditionId,
        conditionName: '',
        conditionDescription: '',
        
    })
    setShowEditConditionModal(false);
    setShowAddPathwayModal(false);
    setShowDeleteConditionModal(false);

   }

    const [formData, setFormData] = useState({
        programId: programId,
        conditionId: conditionId,
        conditionName: '',
        conditionDescription: '',
        pathwayName:'',
    })

    const [errors, setErrors] = useState({
        programId: programId,
        conditionId: conditionId,
        conditionName: '',
        conditionDescription: '',

    })

        //onchange
const onValueChange = (e) =>{    
    setFormData({...formData, [e.target.name]: e.target.value});
  }

    //open Edit condition
    const handleEditCondition = (conditionId) =>{
        editFetchNewCondition(conditionId)
       .then((response) => { 
           setFormData(response.data.data);
       });
       setShowEditConditionModal(true);
    }

    //submit edit program
const submitEditNewCondition = async (e) => {
    e.preventDefault();
    setErrors('')
    const validationErrors = validateFormNewConditionAdd(formData);
    if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
    }else{
      editNewCondition(formData)
        .then((response) => {   
            if(response.data.code == 200){
              setShowEditConditionModal(false);
              sendRefreshToParent();
            }
        });
    }
  }

  //Add new pathway popup
const handleAddPathway = () =>{
    setShowAddPathwayModal(true);
}

//submit new pathway
const submitAddNewPathway = async (e) => {
  e.preventDefault();
  setErrors('')
  const validationErrors = validateFormPathwayAdd(formData);
  if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
  }else{
    AddNewPathway(formData)
      .then((response) => {   
          if(response.data.code == 200){
            setShowAddPathwayModal(false);
            sendRefreshToParent();
          }
      });
  }
}



   //delete program
const handleDeleteNewCondition = () =>{
    setShowDeleteConditionModal(true);
}

//submit delete Condition
const submitDeleteNewCondition = async (e) => {
    e.preventDefault();
    setErrors('');
      removeNewCondition(conditionId)
        .then((response) => {   
            if(response.data.code == 200){
                setShowDeleteConditionModal(false);
                sendRefreshToParent();
            }
        });
  }


return (
    <>
        <div className='DropDownAction'>
        <div class="btn-group dropend">
        <button type="button" class="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-three-dots-vertical"></i>
        </button>
        <ul class="dropdown-menu">
            <li className='list-class'><Link to="" class="dropdown-item" href="#" onClick={(e) => handleEditCondition(conditionId)} aria-label="Edit Condition" title="Edit Condition"><i class="bi bi-pen"></i> Edit Condition</Link></li>
            <li className='list-class'><Link to="" class="dropdown-item" href="#" onClick={() => handleAddPathway()} aria-label="Add Pathway" title="Add Pathway"><i class="bi bi-plus-circle"></i> Add Pathway</Link></li>
            {ifCheck===1 ? (<li className='list-class'><Link to="" class="dropdown-item" href="#" onClick={() => handleDeleteNewCondition()} aria-label="Delete Condition" title="Delete Condition"><i class="bi bi-x-circle text-danger"></i> Delete Condition</Link></li>):null}
        </ul>
        </div>
        </div>
        <Commingsoon />

        <div className={`modal fade ${showEditConditionModal ? 'show' : ''}`} id="showEditConditionModalLabel" tabIndex="-1" aria-labelledby="showEditConditionModalLabel" aria-hidden={!showEditConditionModal} style={{display: showEditConditionModal ? 'block' : 'none'}}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="main-title">Edit Condition</h4>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    <div class="modal-body pt-1 mb-2">
                        <form className='' onSubmit={submitEditNewCondition}> 
                            <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor='Condition' class="form-label">Condition Name</label>
                                        <span style={{color:'red'}}> *</span>
                                        <input type="text"
                                        className="form-control input-field-ui"
                                        placeholder="Enter condition name"
                                        name="conditionName"
                                        onChange={(e) => onValueChange(e)} value={formData['conditionName']}
                                        />
                                        {errors['conditionName'] && <div className="text-danger">{errors['conditionName']}</div>}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor='conditionDescription' class="form-label">Condition Description</label>
                                        <span style={{color:'red'}}> *</span>
                                        <textarea
                                        className="form-control input-field-ui"
                                        placeholder="Enter condition description"
                                        rows="3"
                                        name="conditionDescription"
                                        onChange={(e) => onValueChange(e)} value={formData['conditionDescription']}
                                        />
                                        {errors['conditionDescription'] && <div className="text-danger">{errors['conditionDescription']}</div>}
                                    </div>
                                   
                                    <div className="col-md-12 my-2 text-center">
                                        <button
                                        type="submit"
                                        className="btn btn-primary program-save-btn me-2"
                                        >
                                        Save
                                        </button>
                                    </div>
                                </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>

            <div className={`modal fade ${showAddPathwayModal ? 'show' : ''}`} id="showAddPathwayModalLabel" tabIndex="-1" aria-labelledby="showAddPathwayModalLabel" aria-hidden={!showAddPathwayModal} style={{display: showAddPathwayModal ? 'block' : 'none'}}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="main-title">Add New Pathway</h4>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    <div class="modal-body pt-1 mb-2">
                        <form className='' onSubmit={submitAddNewPathway}> 
                            <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor='pathwayName' class="form-label">Pathway Name</label>
                                        <span style={{color:'red'}}> *</span>
                                        <input type="text"
                                        className="form-control input-field-ui"
                                        placeholder="Enter pathway name"
                                        name="pathwayName"
                                        onChange={(e) => onValueChange(e)} value={formData['pathwayName']}
                                        />
                                        {errors['pathwayName'] && <div className="text-danger">{errors['pathwayName']}</div>}
                                    </div>
                                    <div className="col-md-12 my-2 text-center">
                                        <button
                                        type="submit"
                                        className="btn btn-primary program-save-btn me-2"
                                        >
                                        Save
                                        </button>
                                    </div>
                                </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>

            <div className={`modal fade ${showDeleteConditionModal ? 'show' : ''}`} id="showDeleteConditionModalLabel" tabIndex="-1" aria-labelledby="showDeleteConditionModalLabel" aria-hidden={!showDeleteConditionModal} style={{display: showDeleteConditionModal ? 'block' : 'none'}}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="main-title">Are you sure want to delete this?</p>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    <div class="modal-body pt-1 mb-2">
                        <form className='' onSubmit={submitDeleteNewCondition}> 
                            <div className="row">
                                    
                                   
                                    <div className="col-md-12 my-2 text-center">
                                        <button
                                        type="submit"
                                        className="btn btn-primary program-save-btn me-2"
                                        >
                                        Confirm
                                        </button>
                                    </div>
                                </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>
    </>
  );
}

export default DropDownAction;
