import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReportsDateTable from "./ReportsDateTable";
import { displayUploadedReport} from '../../../services/rcm/Service';
import { CustomLoader, decruptCipherJson } from "../../utils/CommonLibrary";
import CalendarDatePicker from "../CalendarDatePicker";
import moment from "moment";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { decryptData } from "../../utils/Utils";

function ContentProgramsList() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    const [loading, setLoading] = useState('');
    const [logData, setLogData] = useState([]);
    const [inviteMenmberCount, setInviteMenmberCount] = useState(0);
    const [enrolledMenmberCount, setEnrolledMenmberCount] = useState(0);
    const [notEnrolledMenmberCount, setNotEnrolledMenmberCount] = useState(0);

    const [touchCount, setTouchCount] = useState(0);
    const [untouchCount, setUntouchCount] = useState(0);
    const [validCount, setValidCount] = useState(0);
    const [incorrectCount, setIncorrectCount] = useState(0);

    const renderRCM1Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of member records uploaded by the RCM team.
        </Tooltip>
    );

    const renderRCM2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total count of member records successfully migrated/uploaded into the system.
        </Tooltip>
    );

    const renderRCM3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total count of member records that failed during the upload process.
        </Tooltip>
    );

    const renderRCM4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members who have been contacted.
        </Tooltip>
    );

    const renderRCM5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
             Members who have not yet been contacted.
        </Tooltip>
    );

    const renderRCM6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members successfully reached by agents.
        </Tooltip>
    );

    const renderRCM7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members marked as invalid data by agents.
        </Tooltip>
    );

    const stats = [
        { count: inviteMenmberCount, label: 'Members Uploaded', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/vsksd/k88eveca7u.png', tooltip: renderRCM1Tooltip },
        { count: enrolledMenmberCount, label: 'Migrated', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/48t4m/30kswhl360.png', tooltip: renderRCM2Tooltip },
        { count: notEnrolledMenmberCount, label: 'Failed', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/8ogjs/whn8x2c8f4.png', tooltip: renderRCM3Tooltip },
    ];

    const statsNew = [
        { count: touchCount, label: 'Touched', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/vsksd/k88eveca7u.png', tooltip: renderRCM4Tooltip },
        { count: untouchCount, label: 'Untouched', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/48t4m/30kswhl360.pngs', tooltip: renderRCM5Tooltip },
        { count: validCount, label: 'Valid', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/8ogjs/whn8x2c8f4.png', tooltip: renderRCM6Tooltip },
        { count: incorrectCount, label: 'Incorrect', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/8ogjs/whn8x2c8f4.png', tooltip: renderRCM7Tooltip },
    ];
      
    useEffect(() => {
        fetchData();
    }, [startDate,endDate]);
    
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    
    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };  

    const fetchData = async () => {
        setLoading(true);
        displayUploadedReport(formatDate(startDate),formatDate(endDate))
        .then((response) => {
            let showData = decruptCipherJson(response.data.data);
            setLogData(showData.responseData);
            setInviteMenmberCount(showData.inviteMember);
            setEnrolledMenmberCount(showData.enrolledMember);
            setNotEnrolledMenmberCount(showData.notEnrolledMember);

            setTouchCount(showData.touchMember);
            setUntouchCount(showData.untouchMember);
            setValidCount(showData.validMember);
            setIncorrectCount(showData.incorrectMember);
            setLoading(false);
        });
    }

   
    
    return (
        <>
            <div className="container-fluid px-0 mb-3 ">
                <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md ">
                        <h1 className="main-title">Migration Reports</h1>
                    </div>
                    <div className="col-md-3">
                        <select className="form-select input-field-ui" defaultValue="1" id="SelectProgram" aria-label="Default select example">
                            <option >Select Condition</option>
                            <option value="1">Diabetes Mellitus</option>
                        </select>
                    </div>

                    <div className="col-md-3">
                        {/* <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            className="form-control input-field-ui"
                            onChange={getData}
                            isClearable={false}
                            dateFormat={"dd/MM/yyyy"}
                            placeholderText="Select Date"
                        /> */}
                        <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />
                    </div>
                    {/* <div className="col-md-auto ps-0">  
                        <button type="button" onClick={fetchData} id="xlsxSubmit" className="btn btn-success clear-search-btn">Go</button>
                    </div> */}
                </div>
                <div className='reports-programs mb-3'>
                    <div className=" row align-items-center">
                    <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/v3ysv/7sg4f87em4.png" alt="Invited Members icon" style={{height:"140px"}}/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">The Reports allows users to generate and download various reports of the data sets migrated into the system. This feature provides valuable insights and ensures you have comprehensive records of all migrated data.</p>
                        </div>
                    </div>
                </div>

                <div className='member-engagement mb-3'>
                    <div className="row text-center px-2">
                        {stats.map((stat, index) => (
                            <div className="col-4 px-1" >
                                <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%" }}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <h1 className="card-title1">{stat.count}</h1>
                                                <h5 className="card-text1">{stat.label}
                                                    <OverlayTrigger placement="top" overlay={stat.tooltip}>
                                                        <i className='bx bx-info-circle ms-1' style={{"fontSize":"14px"}}></i>
                                                    </OverlayTrigger>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                
                    <div className="row text-center px-2 mt-2">
                        {statsNew.map((stat, index) => (
                            <div className="col px-1" >
                                <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%" }}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <h1 className="card-title1">{stat.count}</h1>
                                                <h5 className="card-text1">{stat.label}
                                                    <OverlayTrigger placement="top" overlay={stat.tooltip}>
                                                        <i className='bx bx-info-circle ms-1' style={{"fontSize":"14px"}}></i>
                                                    </OverlayTrigger>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='reports-programs mb-3'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <ReportsDateTable logData={logData} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            { loading ? <CustomLoader /> : null }
        </>
    );
}

export default ContentProgramsList;
