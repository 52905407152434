import React, { useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { agent_configuration_setting,fetch_program_data,fetch_progtramcondition_data,fetch_agent_setting_script_data, fetch_last_save_setting_list } from '../../../services/met/Service';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { LoaderLoader } from '../../utils/CommonLibrary';
import { formatDate } from '../utils/CommonLibrary';


function ConfigurationSettingsAgentCallsDetails() {

  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    today.setHours(9, 0, 0, 0);
    return today;
  });
  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    today.setHours(18, 0, 0, 0);
    return today;
  });
  const [breakStartDate1, setBreakStartDate1] = useState(() => {
    const today = new Date();
    today.setHours(13, 0, 0, 0);
    return today;
  });
  const [breakEndDate1, setBreakEndDate1] = useState(() => {
    const today = new Date();
    today.setHours(14, 0, 0, 0);
    return today;
  });
  const [breakStartDate2, setBreakStartDate2] = useState(() => {
    const today = new Date();
    today.setHours(16, 0, 0, 0);
    return today;
  });
  const [breakEndDate2, setBreakEndDate2] = useState(() => {
    const today = new Date();
    today.setHours(16, 30, 0, 0); 
    return today;
  });

  const handleDateChange = (setter) => (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);
    selectedDate.setFullYear(currentDate.getFullYear());
    selectedDate.setMonth(currentDate.getMonth());
    selectedDate.setDate(currentDate.getDate());
    setter(selectedDate);
  };

  
    const [programData, setprogramData] = useState([]);
    const [successMsg, setSuccessMsg] = useState('');
     const [enrollmentTarget, setEnrollmentTarget] = useState("15");
     const [callRetries, setCallRetries] = useState("4");
     const [batchSize, setBatchSize] = useState("10");
     const [callRetryGap, setCallRetryGap] = useState("30 minutes");
     const [batchChecked,setBatchChecked] = useState(false);
     const [batchChecked1,setBatchChecked1] = useState(false);
     const [batchChecked2,setBatchChecked2] = useState(false);
     const [batchChecked3,setBatchChecked3] = useState(false);
     const [batchChecked4,setBatchChecked4] = useState(false);
     const [errormsg,setErrormsg] = useState(null);
     const [errormsg1,setErrormsg1] = useState(null);
     const [errormsg2,setErrormsg2] = useState(null);
     
    const [formData, setFormData] = useState({
      batchSize:batchSize,
      enrollmentTarget: enrollmentTarget,
      callRetries: callRetries,
      callRetryGap:callRetryGap,
            
    });
    const { register, handleSubmit, setValue, watch, formState: { errors }, setError, reset, clearErrors } = useForm({
      defaultValues:{
        formData
      }
    });
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [language, setLanguage] = useState('Eng');
    const [script, setScript] = useState({
      DiabetesMellitus: { Eng: '', Arb: '' },
      Hypertension: { Eng: '', Arb: '' },
      Dyslipidemia: { Eng: '', Arb: '' },
    });

   const [agentScriptData, setAgentScriptData] = useState([]);

    
    const formatDateConvertionTime = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
      
     const fetchLastSaveSettingList =async()=>{
      setIsLoading(true); //to show loader
      try {
          let resp = await fetch_last_save_setting_list();
          if(resp.data.code === 200){
              setStartDate(new Date(resp.data.data[0].shiftStartTime));
              setEndDate(new Date(resp.data.data[0].shiftEndTime));
              setBreakStartDate1(new Date(resp.data.data[0].breakStartTime1));
              setBreakEndDate1(new Date(resp.data.data[0].breakEndTime1));
              setBreakStartDate2(new Date(resp.data.data[0].breakStartTime2));
              setBreakEndDate2(new Date(resp.data.data[0].breakEndTime2));
            
            setBatchSize(resp.data.data[0].poolingLimit);
            setValue('batchSize', batchSize.toString());
            if(batchSize.toString() === "10"){
              setBatchChecked(true);
            }else if(batchSize.toString() === "20"){
              setBatchChecked1(true)
            }else  if(batchSize.toString() === "30"){
              setBatchChecked2(true)
            }else if(batchSize.toString() === "40"){
              setBatchChecked3(true)
            }else if(batchSize.toString() === "50"){
              setBatchChecked4(true)
            }

            setEnrollmentTarget(resp.data.data[0].enrollmentTargetBatch.toString());
            setValue('enrollmentTarget', enrollmentTarget);

            setCallRetries(resp.data.data[0].callAttempt.toString());
            setValue('callRetries', callRetries);

            setCallRetryGap(resp.data.data[0].nextAttempedDifference.toString());
            setValue('callRetryGap', callRetryGap);

            


          }
          
      } catch (error) {
          console.error(error);
      }finally {
        setIsLoading(false); // Will be called regardless of success or failure
        }

     }

    
     const areDatesSame = (dateStr1, dateStr2) => {
      let date1 = new Date(dateStr1);
      let date2 = new Date(dateStr2);
      const d1 = date1.toISOString().split('T')[0];
      const d2 = date2.toISOString().split('T')[0];
      return d1 === d2;
    };

    const isValidDateRange = (startStr, endStr) => {
      const startDate = new Date(startStr);
      const endDate = new Date(endStr);
    
      return startDate < endDate;
    };

    // setEnrollBatch(watch("enrollmentTarget"))

    const onSubmit = async(formData) => {
      console.log(formData.enrollmentTarget);
      console.log(startDate);
      const shiftStartTime = formatDateConvertionTime(startDate);
      
        const shiftEndTime = formatDateConvertionTime(endDate);
        const breakStartTime = formatDateConvertionTime(breakStartDate1);
        const breakEndTime = formatDateConvertionTime(breakEndDate1);
        const breakStartTime1 = formatDateConvertionTime(breakStartDate2);
        const breakEndTime1 = formatDateConvertionTime(breakEndDate2);
        let sdate = formatDate(shiftStartTime);
        console.log('sdate',sdate);
        let edate = formatDate(shiftEndTime);
        console.log('edate',edate);
        if(!areDatesSame(sdate, edate) || startDate.getTime() === endDate.getTime()) {
          setErrormsg('Select valid shift start and end times.');
          return;
        }

        if(!isValidDateRange(shiftStartTime, shiftEndTime)) {
          setErrormsg('Select valid shift start and end times.');
          return;
        }

        if (breakStartDate1.getTime() < startDate.getTime() || breakEndDate1.getTime() > endDate.getTime()) {
          setErrormsg1('Break times must be within the shift times.');
          return;
        }

        if (breakStartDate1.getTime() === breakStartDate2.getTime() ||  breakStartDate1.getTime() > breakEndDate1.getTime()) {
          setErrormsg1('Break times cannot be identical.');
            return;
        }

        if (breakEndDate1.getTime() === breakEndDate2.getTime() ||  breakStartDate2.getTime() > breakEndDate2.getTime()) {
          setErrormsg2('Break times cannot be identical.');
            return;
        }

        if (breakStartDate2.getTime() < startDate.getTime() || breakEndDate2.getTime() > endDate.getTime()) {
          setErrormsg2('Break times must be within the shift times.');
          return;
        }
      
        if (
            breakStartDate1.getTime() < startDate.getTime() || breakStartDate1.getTime() > endDate.getTime() ||
            breakEndDate1.getTime() < startDate.getTime() || breakEndDate1.getTime() > endDate.getTime() ||
            breakStartDate2.getTime() < startDate.getTime() || breakStartDate2.getTime() > endDate.getTime() ||
            breakEndDate2.getTime() < startDate.getTime() || breakEndDate2.getTime() > endDate.getTime()
        ) {
          setErrormsg2('Break times must be within shift hours.');
            return;
        }
      const payload = {
        ...formData,
        shiftStartTime: shiftStartTime,
        shiftEndTime: shiftEndTime,
        breakStartTime: breakStartTime,
        breakEndTime: breakEndTime,
        breakStartTime1: breakStartTime1,
        breakEndTime1: breakEndTime1,
      };
      
      if(!parseInt(payload.enrollmentTarget)){
        setError('enrollmentTarget', { type: 'manual', message: 'Requires a numeric value.' });
        return;
      }else if(parseInt(payload.batchSize) < parseInt(payload.enrollmentTarget)){
        setError('enrollmentTarget', { type: 'manual', message: 'Daily Enrollment Target should be less than Calling Data Per Batch' });
        return;
      }
      
      
      setIsLoading(true);

      console.log("payload",payload);

      setFormSubmitted(true);
      setIsLoading(true); //show loader
      console.log('before');
      
        console.log('after');

            try {

                  const response = await agent_configuration_setting(payload);
                  console.log(response.data)
                  if(response.data.code == 200){
                      console.log(response.data.data.message);
                      setSuccessMsg(response.data.data.message);
                      setStartDate(new Date());
                      setEndDate(new Date());
                      setBreakStartDate1(new Date());
                      setBreakEndDate1(new Date());
                      setBreakStartDate2(new Date());
                      setBreakEndDate2(new Date());
                      setErrormsg('');
                      setErrormsg1('');
                      setErrormsg2('');
                     


                      //reset();
                  }else{
                      //setErrorMessage(response.data.data.message);
                  }
                  //message disappear 
              setTimeout(() => {
                  setSuccessMsg("");
                  fetchLastSaveSettingList();
                }, 1000);
                
              }catch (error) {
                  console.error(error);
              }
                  finally{
                  setIsLoading(false); //show loader
                  }
          
        }
  
    const handleLanguageChange = (condition, lang) => {
      setLanguage(lang);
      // Trigger re-render to show the correct textarea content
      setScript({ ...script });
    };
  
    const handleScriptChange = (condition, lang, value) => {
      setScript(prevState => ({
        ...prevState,
        [condition]: {
          ...prevState[condition],
          [lang]: value,
        },
      }));
    };

    const handleFormDataChange = (key, value) => {

      // switch (field) {
      //   case "batchSize":
      //     console.log("value",value);
      //     setBatchSize(value);
      //     setValue('batchSize', value.toString());
      //     break;
      //   // Handle other fields similarly...
      //   default:
      //     break;
      // }
      setBatchChecked1(false);
     
      setFormData(prevState => ({
        ...prevState,
        [key]: value
      }));
    };
    //fetch program data
const fetchProgramData = async () => {
    try {
        const resp = await fetch_progtramcondition_data();
        if(resp.data.code == 200){
            console.log(resp.data);
            setprogramData(resp.data.data);
        }    
    } catch (error) {
        console.error(error);
    }
}

const fetchAgentScript = async () => {
    try {
        const resp = await fetch_agent_setting_script_data();
        if(resp.data.code == 200){
            console.log(resp.data);
            setAgentScriptData(resp.data.data);
            const keyValueArray = [
                { key: 'eng', value: resp.data.data[1].script },
                { key: 'arb', value: resp.data.data[0].script },
            ];
            const newScript = {
              DiabetesMellitus: { Eng: resp.data.data[1].script, Arb: resp.data.data[0].script },
              Hypertension: { Eng: '', Arb: '' },
              Dyslipidemia: { Eng: '', Arb: '' },
            };
            setScript(newScript);

              setValue('DiabetesMellitus.Eng', newScript.DiabetesMellitus.Eng);
              setValue('DiabetesMellitus.Arb', newScript.DiabetesMellitus.Arb);
        }    
    } catch (error) {
        console.error(error);
    }
}

useEffect(() => {
    fetchProgramData();
    fetchAgentScript();
    fetchLastSaveSettingList();
},[setValue,batchSize,enrollmentTarget,callRetries,callRetryGap]);



    const shiftStartTime = watch('shiftStartTime');
    const shiftEndTime = watch('shiftEndTime');
    const breakStartTime = watch('breakStartTime');
    const breakEndTime = watch('breakEndTime');
    const breakStartTime1 = watch('breakStartTime1');
    const breakEndTime1 = watch('breakEndTime1');

   
  return (
    <>
        <section className='ConfigurationSettingsAgentCall'>
            <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md-12">
                    <h1 className="main-title">Agent Call Settings</h1>
                </div>
            </div>

            <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/5jw18/wh4l7e7hk4.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">The agent Call Settings allows you to easily manage all settings for agents making calls to enroll members in programs. You can configure call batches, retries, target enrollments, and specific scripts for various programs and conditions.</p>
                        </div>
                    </div>
                </div>

            <div className="container-fluid">
                <div className="row align-items-center pb-3">
                    <div className='comntent-ConfigurationSettingsAgentCall p-3'>
                        <LoaderLoader isLoading={isLoading} />
                        <form onSubmit={handleSubmit(onSubmit)} className="row">
                        
                        <div className="col-md-8 pe-4">
                          <div className="row">
                            <div className="col-md-6 mb-4">
                            <div className='row'>
                              <div className="col-12 mb-2">
                                <h1 className="ConfigurationSettings-title mb-0">Calling Data per Batch   </h1>
                              </div>
                              <div className="col-12">
                                
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" name='callingDataRadio' id="callingDataRadio1" type="radio" {...register('batchSize', { required: true })} value="10"  onChange={() => handleFormDataChange("batchSize", 10)} />
                                  <label htmlFor='callingDataRadio1' className="form-check-label pt-1">10</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  { batchChecked1 ? 
                                  (<input className="form-check-input" name='callingDataRadio' id="callingDataRadio2" type="radio" {...register('batchSize', { required: true })} value="20" onChange={() => handleFormDataChange("batchSize", 20)} checked />):(<input className="form-check-input" name='callingDataRadio' id="callingDataRadio2" type="radio" {...register('batchSize', { required: true })} value="20" onChange={() => handleFormDataChange("batchSize", 20)} />)}
                                  <label htmlFor='callingDataRadio2' className="form-check-label pt-1">20</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" name='callingDataRadio' id="callingDataRadio3" type="radio" {...register('batchSize', { required: true })} value="30" onChange={() => handleFormDataChange("batchSize", 30)}  />
                                  <label htmlFor='callingDataRadio3' className="form-check-label pt-1">30</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" name='callingDataRadio' id="callingDataRadio4" type="radio" {...register('batchSize', { required: true })} value="40" onChange={() => handleFormDataChange("batchSize", 40)} />
                                  <label htmlFor='callingDataRadio4' className="form-check-label pt-1">40</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" name='callingDataRadio' id="callingDataRadio5" type="radio" {...register('batchSize', { required: true })} value="50" onChange={() => handleFormDataChange("batchSize", 50)} />
                                  <label htmlFor='callingDataRadio5' className="form-check-label pt-1">50</label>
                                </div>
                                <br/>
                                {errors.batchSize && <span className="text-danger">This field is required</span>}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 mb-4">
                          <div className='row'>
                            <div className="col-12 mb-2">
                              <h1 className="ConfigurationSettings-title mb-0">Daily Enrollment Target Per Agent <span style={{color:"red"}}>*</span></h1>
                            </div>
                            <div className="col-12">
                            <input type="number" class="input-field-ui" placeholder="Select Daily Conversion Target"   min={1} {...register('enrollmentTarget', { required: true,})} maxLength="2"/>
                            {/* <select className="form-select input-field-ui" {...register('enrollmentTarget', { required: true })}>
                                  <option value="">Select</option>
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                              </select> */}
                            {errors.enrollmentTarget && <p className="text-danger">{errors.enrollmentTarget.message ||  'This field is required'}</p>}
                            </div>
                          </div>
                        </div>

                          <div className="col-md-6 mb-4">
                            <div className='row'>
                              <div className="col-12 mb-2">
                                <h1 className="ConfigurationSettings-title mb-0">Call Retries if Not Answered   </h1>
                              </div>
                              <div className="col-12">
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" name='callRetriesRadio' id="callRetriesRadio1" type="radio" {...register('callRetries', { required: true })} value="2"  onChange={() => handleFormDataChange("callRetries", "2")}/>
                                  <label htmlFor='callRetriesRadio1' className="form-check-label pt-1">2</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" name='callRetriesRadio' id="callRetriesRadio2" type="radio" {...register('callRetries', { required: true })} value="3" onChange={() => handleFormDataChange("callRetries", "3")} />
                                  <label htmlFor='callRetriesRadio2' className="form-check-label pt-1">3</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" name='callRetriesRadio' id="callRetriesRadio3" type="radio" {...register('callRetries', { required: true })} value="4" onChange={() => handleFormDataChange("callRetries", "4")}/>
                                  <label htmlFor='callRetriesRadio3' className="form-check-label pt-1">4</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" name='callRetriesRadio' id="callRetriesRadio4" type="radio" {...register('callRetries', { required: true })} value="5" onChange={() => handleFormDataChange("callRetries", "5")} />
                                  <label htmlFor='callRetriesRadio4' className="form-check-label pt-1">5</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" name='callRetriesRadio' id="callRetriesRadio5" type="radio" {...register('callRetries', { required: true })} value="6" onChange={() => handleFormDataChange("callRetries", "6")} />
                                  <label htmlFor='callRetriesRadio5' className="form-check-label pt-1">6</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" name='callRetriesRadio' id="callRetriesRadio6" type="radio" {...register('callRetries', { required: true })} value="7" onChange={() => handleFormDataChange("callRetries", "7")} />
                                  <label htmlFor='callRetriesRadio6' className="form-check-label pt-1">7</label>
                                </div>
                                <br/>
                                {errors.callRetries && <span className="text-danger">This field is required</span>}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className='row'>
                              <div className="col-12 mb-2">
                                <h1 className="ConfigurationSettings-title mb-0">Call Retries Minimum Gap Before Next Attempt   </h1>
                              </div>
                              <div className="col-12">
                              <div className="form-check form-check-inline">
                                  <input className="form-check-input" name='callRetriesAttemptRadio' id="callRetriesAttemptRadio1" type="radio" {...register('callRetryGap', { required: true })} value="10 minutes"/>
                                  <label htmlFor='callRetriesAttemptRadio1' className="form-check-label pt-1">10 min</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" name='callRetriesAttemptRadio' id="callRetriesAttemptRadio2" type="radio" {...register('callRetryGap', { required: true })} value="20 minutes" />
                                  <label htmlFor='callRetriesAttemptRadio2' className="form-check-label pt-1">20 min</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" name='callRetriesAttemptRadio' id="callRetriesAttemptRadio3" type="radio" {...register('callRetryGap', { required: true })} value="30 minutes" defaultChecked/>
                                  <label htmlFor='callRetriesAttemptRadio3' className="form-check-label pt-1">30 min</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" name='callRetriesAttemptRadio' id="callRetriesAttemptRadio4" type="radio" {...register('callRetryGap', { required: true })} value="40 minutes" />
                                  <label htmlFor='callRetriesAttemptRadio4' className="form-check-label pt-1">40 min</label>
                                </div>
                                {errors.callRetryGap && <span className="text-danger">This field is required</span>}
                              </div>
                            </div>
                          </div>

                          <div className='col-md-12 mb-4'>
                            <div className='bg-light p-3 input-field-ui'>
                              <div className='row'>
                                  
                          <div className="col-md-9 mb-4">
                            <div className='row'>
                              <div className="col-12 mb-2">
                                <h1 className="ConfigurationSettings-title mb-0">Agent Working Hours   </h1>
                              </div>
                              <div className="col-4 pe-1" style={{position:'relative'}}>
                              <DatePicker
                                  selected={startDate}
                                  onChange={handleDateChange(setStartDate)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control input-field-ui ps-5"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                 }}
                                />
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/7sjac/l8s4x2s84s.png" className='clock-icon1' alt="" />
                               
                                {/* <input type="time" className="form-control input-field-ui" {...register('shiftStartTime', { required: true })} /> */}
                                <label className="col-form-label">Shift Start Time</label><br/>
                                {errors.shiftStartTime && <span className="text-danger">{errors.shiftStartTime.message || 'This field is required'}</span>}
                              </div>
                              <div className="col-4 ps-1" style={{position:'relative'}}>
                              <DatePicker
                                  selected={endDate}
                                  
                                  onChange={handleDateChange(setEndDate)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control input-field-ui ps-5"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                 }}
                                />
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/7sjac/l8s4x2s84s.png" className='clock-icon' alt="" />
                                {/* <input type="time" className="form-control input-field-ui" {...register('shiftEndTime', { required: true })} /> */}
                                <label className="col-form-label">Shift End Time</label><br/>
                                {errormsg && <div className="text-danger">{errormsg}</div>}
                              </div>
                              <div className="col">
                                {/* <h1 className="ConfigurationSettings-title mt-3 mb-0">(9 Hours)</h1> */}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className='row'>
                              <div className="col-12 mb-2">
                                <h1 className="ConfigurationSettings-title mb-0">Break Time   </h1>
                              </div>
                              <div className="col-3 pe-1" style={{position:'relative'}}>
                              <DatePicker
                                  selected={breakStartDate1}
                                  onChange={handleDateChange(setBreakStartDate1)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control input-field-ui ps-5"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                 }}
                                />
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/7sjac/l8s4x2s84s.png" className='clock-icon1' alt="" />
                                {/* <input type="time" className="form-control input-field-ui" {...register('breakStartTime', { required: true })}  /> */}
                                <label className="col-form-label">Break Time 1</label><br/>
                                {errormsg1 && <div className="text-danger">{errormsg1}</div>}
                              </div>
                              <div className="col-3 ps-1" style={{position:'relative'}}>
                              <DatePicker
                                  selected={breakEndDate1}
                                  onChange={handleDateChange(setBreakEndDate1)}
                                  
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control input-field-ui ps-5"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                 }}
                                />
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/7sjac/l8s4x2s84s.png" className='clock-icon' alt="" />
                                {/* <input type="time" className="form-control input-field-ui" {...register('breakEndTime', { required: true })} /> */}
                                {errors.breakEndTime && <span className="text-danger">{errors.breakEndTime.message || 'This field is required'}</span>}
                              </div>
                              <div className="col-3 pe-1" style={ {position:'relative'}}>
                              <DatePicker
                                  selected={breakStartDate2}
                                  onChange={handleDateChange(setBreakStartDate2)}
                                  
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control input-field-ui ps-5"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                 }}
                                />
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/7sjac/l8s4x2s84s.png" className='clock-icon1' alt="" />
                                {/* <input type="time" className="form-control input-field-ui" {...register('breakStartTime1', { required: true})} /> */}
                                <label className="col-form-label">Break Time 2</label><br/>
                                {errormsg2 && <div className="text-danger">{errormsg2}</div>}
                              </div>
                              <div className="col-3 ps-1" style={ {position:'relative'}}>
                              <DatePicker
                                  selected={breakEndDate2}
                                  onChange={handleDateChange(setBreakEndDate2)}
                                  
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control input-field-ui ps-5"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                 }}
                                />
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/7sjac/l8s4x2s84s.png" className='clock-icon' alt="" />
                                {/* <input type="time" className="form-control input-field-ui" {...register('breakEndTime1', { required: true})} /> */}
                                {errors.breakEndDate2 && <span className="text-danger">{errors.breakEndDate2.message || 'This field is required'}</span>}
                              </div>
                            </div>

                            
                          </div>
                              </div>
                              </div>
                          </div>


                          </div>
      </div>
      <div className="col-md-4 mb-3 ps-4 border-start">
        <div className='row mb-3'>
                            <div className="col-12 mb-2">
                              <h1 className="ConfigurationSettings-title mb-0">Select Condition for Script </h1>
                            </div>
                            <div className="col-12">
                            <select className="form-select input-field-ui" {...register('programId', { required: true })}>
                                <option value="1" selected>Diabetes Mellitus</option>
                                {/* {programData.map(option => (
                                                <option key={option.conditionId} value={option.conditionId} selected>{option.conditionName}</option>
                                            ))
                                } */}
                              </select>
                            {/* {errors.programId && <p className="text-danger">This field is required</p>} */}
                            </div>
                </div>
          <div className='row mb-1 align-items-center'>
            <div className='col'>
              <label className="ConfigurationSettings-title mb-0">Langauage   </label>
            </div>
            <div className='col-auto'>
              <div className="form-check form-check-inline mb-0">
                <input className="form-check-input" type="radio" name="diabetesLanguage" id="diabetesLanguageRadio1"  onClick={() => handleLanguageChange('DiabetesMellitus', 'Eng')} defaultChecked/>
                <label htmlFor="diabetesLanguageRadio1" className="form-check-label pt-1">English</label>
              </div>
              <div className="form-check form-check-inline mb-0">
                <input className="form-check-input" type="radio" name="diabetesLanguage" id="diabetesLanguageRadio2" onClick={() => handleLanguageChange('DiabetesMellitus', 'Arb')} />
                <label htmlFor="diabetesLanguageRadio2" className="form-check-label pt-1">Arabic</label>
              </div>
            </div>
          </div>
          <textarea
            className="form-control input-field-ui"
            placeholder="Program/Condition Specific Script"
            rows="13"
            value={script.DiabetesMellitus[language]}
            {...register(`DiabetesMellitus.${language}`, { required: true })}
            onChange={(e) => handleScriptChange('DiabetesMellitus', language, e.target.value)}

            style={language == 'Arb' ? { direction: 'rtl' } : {}}
          ></textarea>
          {errors.DiabetesMellitus && (
            <span className="text-danger">This field is required</span>
          )}
      </div>
      {successMsg && <div className="col-md-12 mt-2 mb-4 text-center">
                          <h1 className="ConfigurationSettings-title mb-0 text-center"><span style={{color:'green'}}>{successMsg}</span></h1>
                        </div> }
      <div className="col-12 mb-2 text-center">
        <button type="submit" className="btn btn-dark program-save-btn">Save</button>
      </div>
      

    </form>
                        


                        </div>
                    </div>
            </div>
        </section>      
    </>
  );
}

export default ConfigurationSettingsAgentCallsDetails;
