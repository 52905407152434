import React, { useState } from 'react';
import { useInboxContext } from './InboxContext';
import { FiX, FiChevronDown, FiCheck, FiSearch } from 'react-icons/fi';
import { BiCheckDouble } from "react-icons/bi";
import { ImUserCheck } from "react-icons/im";

const ChatArea = () => {
  const { selectedContact } = useInboxContext();
  const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
  const [searchAssignee, setSearchAssignee] = useState('');
  const [activeTab, setActiveTab] = useState('reply');
  const [showTemplatePopup, setShowTemplatePopup] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [noteText, setNoteText] = useState('');

  const dummyAssignees = [
    'John Doe',
    'Jane Smith',
    'Alice Johnson',
    'Bob Williams',
    'Charlie Brown',
    'Diana Ross',
    'Edward Norton',
    'Fiona Apple'
  ];

  const filteredAssignees = dummyAssignees.filter(assignee => 
    assignee.toLowerCase().includes(searchAssignee.toLowerCase())
  );

  if (!selectedContact) {
    return (
      <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
        <p className="tw-text-gray-500">Select a contact to start chatting</p>
      </div>
    );
  }


  return (
    <div className="tw-flex tw-flex-col tw-h-full tw-bg-gray-100">
      {/* Chat header */}
      <div className="tw-p-4 tw-bg-white tw-border-b tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-start tw-flex-col">
        <h1 className='tw-font-bold tw-text-xl'>Abdulaziz Al Dhaheri</h1>
        <h2 className="tw-font-bold tw-text-sm tw-text-gray-500">{selectedContact.number}</h2>
        </div>
        <div className="tw-flex tw-items-center">
          {/* <div className="tw-relative">
            <button 
              className="tw-bg-gray-200 tw-text-gray-700 tw-px-3 tw-py-2 tw-rounded tw-mr-2 tw-flex tw-items-center tw-gap-1"
              onClick={() => setShowAssigneeDropdown(!showAssigneeDropdown)}
            >
              <ImUserCheck size={16} className="tw-inline" />
              <span className="tw-mr-1">Assignee</span>
              <FiChevronDown size={16} className="tw-inline" />
            </button>
            {showAssigneeDropdown && (
              <div className="tw-absolute tw-right-0 tw-mt-2 tw-w-64 tw-bg-white tw-border tw-border-[#03335b] tw-rounded-md tw-shadow-lg tw-z-10">
                <div className="tw-p-2">
                  <div className="tw-relative">
                    <input
                      type="text"
                      placeholder="Search assignees"
                      className="tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded-md"
                      value={searchAssignee}
                      onChange={(e) => setSearchAssignee(e.target.value)}
                    />
                    <FiSearch className="tw-absolute tw-right-3 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-text-gray-400" />
                  </div>
                </div>
                <ul className="tw-max-h-48 tw-overflow-y-auto">
                  {filteredAssignees.map((assignee, index) => (
                    <li 
                      key={index}
                      className="tw-px-4 tw-py-2 tw-hover:bg-gray-100 tw-cursor-pointer"
                      onClick={() => {
                        // Handle assignee selection here
                        setShowAssigneeDropdown(false);
                      }}
                    >
                      {assignee}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div> */}
          <button className="tw-bg-[#03335b] tw-transition-all tw-duration-300 hover:tw-bg-blue-800 tw-text-white tw-px-4 tw-py-2 tw-rounded">Close chat</button>
        </div>
      </div>

      {/* Chat messages */}
      <div className="tw-flex-grow tw-overflow-y-auto tw-p-4">
        {/* Bot message */}
        <div className="tw-mb-4 tw-flex tw-justify-end tw-gap-2">
          <div className="tw-max-w-[70%]">
            <div className="tw-bg-blue-100 tw-rounded-lg tw-p-3 tw-inline-block">
              received. Shipping details will follow. For any queries, please contact us using your GOQii app.
            </div>
            <div className="tw-flex tw-items-center tw-justify-end tw-mt-1">
              <span className="tw-text-xs tw-text-gray-500 tw-mr-2">13 Sep, 01:42 pm</span>
              <BiCheckDouble className="tw-w-4 tw-h-4 tw-text-blue-500" />
            </div>
          </div>
          <div className="tw-w-10 tw-h-10 tw-bg-[#03335b] tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mr-2 tw-mt-2">
            S
          </div>
        </div>

        {/* User message */}
        <div className="tw-mb-4 tw-flex">
          <div className="tw-w-10 tw-h-10 tw-bg-[#03335b] tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mr-2 tw-mt-2">
            R
          </div>
          <div className="tw-max-w-[70%]">
            <div className="tw-bg-blue-100 tw-rounded-lg tw-p-3 tw-shadow">
              Delivery late today
            </div>
            <div className="tw-text-xs tw-text-gray-500 tw-mt-1">16 Sep, 03:30 pm</div>
          </div>
        </div>

        {/* Bot message */}
        <div className="tw-mb-4 tw-flex tw-justify-end tw-gap-2">
          <div className="tw-max-w-[70%]">
            <div className="tw-bg-blue-100 tw-rounded-lg tw-p-3 tw-shadow">
              Thank you for contacting us.<br />
              For any queries please reach us via<br />
              GOQii App -&gt; Home -&gt; Support<br />
              https://bit.ly/3wB8aUZ
            </div>
            <div className="tw-flex tw-items-center tw-justify-end tw-mt-1">
              <span className="tw-text-xs tw-text-gray-500 tw-mr-2">16 Sep, 03:30 pm</span>
              <FiCheck className="tw-w-4 tw-h-4 tw-text-blue-500" />
            </div>
          </div>
          <div className="tw-w-10 tw-h-10 tw-bg-[#03335b] tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mr-2 tw-mt-2">
            S
          </div>
        </div>
      </div>

      {/* Chat input area */}
      <div className="tw-bg-white tw-border-t tw-flex-shrink-0">
  <div className="tw-p-4 tw-flex tw-justify-between tw-items-center tw-border-b">
    <div className="tw-flex">
      <button
        className={`tw-px-4 tw-py-2 tw-font-semibold tw-appearance-none tw-transition-all tw-duration-300 ${activeTab === 'reply' ? 'tw-border-b !tw-border-[#03335b]' : ''}`}
        onClick={() => setActiveTab('reply')}
      >
        Reply
      </button>
      <button
        className={`tw-px-4 tw-py-2 tw-font-semibold tw-appearance-none tw-transition-all tw-duration-300 ${activeTab === 'notes' ? 'tw-border-b !tw-border-[#03335b]' : ''}`}
        onClick={() => setActiveTab('notes')}
      >
        Notes
      </button>
    </div>
    <button
      className="tw-border-2 tw-border-[#03335b] tw-text-[#03335b] hover:tw-bg-[#03335b] hover:tw-text-white tw-transition-all tw-duration-300 tw-font-bold tw-px-4 tw-py-2 tw-rounded tw-flex tw-items-center"
      onClick={() => setShowTemplatePopup(true)}
    >
      <span className="tw-mr-2">Choose template</span>
      <FiChevronDown className='tw-font-bold' />
    </button>
  </div>
  <div className="tw-p-4 tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2">
    {activeTab === 'reply' ? (
      <div className="tw-flex tw-flex-col tw-w-full">
        <div className="tw-mb-4 tw-w-full tw-text-gray-600 tw-text-center tw-bg-gray-100 tw-p-5 tw-rounded">
          The customer has not spoken to you in the last 24 hours.
          <br />
          You can only send pre-approved templates.
        </div>
      </div>
    ) : (
      <textarea
        className="tw-w-full tw-border tw-rounded tw-p-2 tw-mb-4"
        placeholder="Add notes here"
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
        rows={3}
      />
    )}
    <div className="tw-flex tw-justify-end">
      <button
        className="tw-bg-[#03335b] tw-transition-all tw-duration-300 hover:tw-bg-blue-800 tw-text-white tw-px-8 tw-py-2 tw-rounded"
        onClick={() => {/* Handle send */}}
      >
        Send
      </button>
    </div>
  </div>
</div>

      {showTemplatePopup && (
        <TemplatePopup onClose={() => setShowTemplatePopup(false)} />
      )}
    </div>
  );
};

const TemplatePopup = ({ onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const templates = [
    'SCORAD sample 2 (English)',
    'rating_feedback_ (English)',
    'sign_up_n_appointment_2 (English)',
    'sign_up_and_appointment_flow (English)',
    'test_chirag (English)',
    'test_flow_2 (English)',
    'test_flow (English)',
    'self_enrollment (English)',
    'booking_flow (English)',
  ];

  const filteredTemplates = templates.filter(template =>
    template.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
      <div className="tw-bg-white tw-rounded-lg tw-overflow-hidden tw-w-3/4 tw-h-3/4 tw-flex tw-flex-col">
        <div className="tw-flex tw-justify-between tw-items-center tw-p-4 tw-border-b tw-bg-[#03335b] tw-text-white">
          <h2 className="tw-text-xl tw-font-bold">Select template</h2>
          <button onClick={onClose}><FiX /></button>
        </div>
        <div className="tw-flex tw-flex-grow tw-overflow-hidden">
          <div className="tw-w-1/3 tw-border-r tw-p-4 tw-flex tw-flex-col">
            <div className="tw-mb-4">
              <input
                type="text"
                placeholder="Search"
                className="tw-w-full tw-border tw-rounded tw-p-2"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="tw-overflow-y-auto">
              {filteredTemplates.map((template, index) => (
                <div
                  key={index}
                  className={`tw-p-2 tw-cursor-pointer tw-transition-all tw-duration-300 tw-hover:bg-gray-100 ${selectedTemplate === template ? 'tw-bg-gray-200' : ''}`}
                  onClick={() => setSelectedTemplate(template)}
                >
                  {template}
                </div>
              ))}
            </div>
          </div>
          <div className="tw-w-2/3 tw-p-4">
            {selectedTemplate && (
              <div>
                <h3 className="tw-font-bold tw-mb-2">Message</h3>
                <div className="tw-border tw-rounded tw-p-4 tw-mb-4">
                  <p className="tw-font-semibold">Body:</p>
                  <p>Hello, To help us monitor the progress of your eczema, Please complete the SCORAD (Scoring Atopic Dermatitis) assessment. The assessment will guide us in measuring the severity of your condition and adjust treatment as needed.</p>
                  <p className="tw-font-semibold tw-mt-4">Buttons:</p>
                  <p>Take Assessment</p>
                  <p className="tw-text-sm tw-text-gray-500">{`https://derme-mia.goqii.com/scorad-assessment?id={{1}}`}</p>
                </div>
                <div>
                  <h4 className="tw-font-semibold">Buttons</h4>
                  <table className="tw-w-full tw-border-collapse">
                    <thead>
                      <tr>
                        <th className="tw-border tw-p-2">Variable</th>
                        <th className="tw-border tw-p-2">Value</th>
                        <th className="tw-border tw-p-2">Fallback/Constant Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="tw-border tw-p-2">
                          <input type="text" className="tw-w-full" placeholder="{{1}}" />
                        </td>
                        <td className="tw-border tw-p-2">
                          <select className="tw-w-full">
                            <option>Use Constant Value</option>
                          </select>
                        </td>
                        <td className="tw-border tw-p-2">
                          <input type="text" className="tw-w-full" placeholder="Fallback value" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="tw-p-4 tw-border-t tw-flex tw-justify-end">
          <button className="tw-bg-[#03335b] tw-transition-all tw-duration-300 hover:tw-bg-blue-800 tw-text-white tw-px-4 tw-py-2 tw-rounded">
            See preview and send
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatArea;