import React, { useState } from 'react';
import { useInboxContext } from './InboxContext';
import { FiUser, FiPhone, FiCalendar, FiMapPin, FiInfo, FiChevronDown, FiChevronUp } from 'react-icons/fi';

const UserDetails = () => {
  const { selectedContact } = useInboxContext();
  const [openAccordion, setOpenAccordion] = useState(null);

  if (!selectedContact) {
    return (
      <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
        <p className="tw-text-gray-500">Select a contact to view details</p>
      </div>
    );
  }

  // Mock data for main user
  const mainUserDetails = {
    name: 'Abdulaziz Al Dhaheri',
    memberId: '6010175',
    careNavigator: 'Mr. John Wick',
    mainMembershipNo: '1231',
    policyMemberType: 'Student',
    mobileNo: '2222567918',
    alternateMobileNo: '',
    contractNo: '46889700',
    contractName: 'SAUDI ARAMCO TOTAL REFINING & PETROCHEMICAL Co. (SATORP)',
    gender: 'M',
    city: 'Riyadh',
    policyStartDate: '25 May 2024',
    policyEndDate: '25 May 2025',
    dateOfBirth: '15 Mar 1990',
    chronicConditions: 'Diabetes Mellitus'
  };

  // Mock data for family member
  const familyMemberDetails = {
    name: 'Fatima Al Dhaheri',
    memberId: '6010176',
    careNavigator: 'Mr. John Wick',
    mainMembershipNo: '1232',
    policyMemberType: 'Dependent',
    mobileNo: '2222567919',
    alternateMobileNo: '',
    contractNo: '46889700',
    contractName: 'SAUDI ARAMCO TOTAL REFINING & PETROCHEMICAL Co. (SATORP)',
    gender: 'F',
    city: 'Riyadh',
    policyStartDate: '25 May 2024',
    policyEndDate: '25 May 2025',
    dateOfBirth: '20 Jun 1992',
    chronicConditions: 'None'
  };

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  return (
    <div className="tw-h-full tw-overflow-y-auto tw-bg-gray-50 tw-p-4">
      <AccordionItem
        index={0}
        isOpen={openAccordion === 0}
        toggle={toggleAccordion}
        userDetails={mainUserDetails}
      />
      <AccordionItem
        index={1}
        isOpen={openAccordion === 1}
        toggle={toggleAccordion}
        userDetails={familyMemberDetails}
      />
    </div>
  );
};

const AccordionItem = ({ index, isOpen, toggle, userDetails }) => (
  <div className="tw-mb-2">
    <button
      className="tw-w-full tw-flex tw-justify-between tw-items-center tw-p-4 tw-bg-[#03335b] tw-text-white tw-rounded tw-shadow-md tw-text-xl tw-font-bold tw-transition-colors tw-duration-300 hover:tw-bg-blue-900"
      onClick={() => toggle(index)}
    >
      {userDetails.name}
      <div className={`tw-transition-transform tw-duration-300 ${isOpen ? 'tw-rotate-180' : ''}`}>
        <FiChevronDown />
      </div>
    </button>
    <div
      className={`tw-overflow-hidden tw-transition-all tw-duration-300 tw-ease-in-out ${
        isOpen ? 'tw-max-h-[2000px] tw-opacity-100' : 'tw-max-h-0 tw-opacity-0'
      }`}
    >
      <UserDetailsContent userDetails={userDetails} />
    </div>
  </div>
);

const UserDetailsContent = ({ userDetails }) => (
  <div className="tw-bg-white tw-rounded-b-lg tw-shadow-md tw-p-4">
    <div className="tw-flex tw-flex-col tw-items-center tw-mb-6">
      <div className="tw-w-24 tw-h-24 tw-bg-[#03335b] tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-white tw-text-3xl tw-font-bold tw-mb-2">
        {userDetails.name.charAt(0)}
      </div>
      <p className="tw-text-sm tw-text-gray-600">Member ID: {userDetails.memberId}</p>
      <p className="tw-text-sm tw-text-gray-600">Care Navigator: {userDetails.careNavigator}</p>
    </div>

    <DetailSection title="Membership Details">
      <DetailItem icon={<FiInfo />} label="Main Membership No" value={userDetails.mainMembershipNo} />
      <DetailItem icon={<FiInfo />} label="Policy Member Type" value={userDetails.policyMemberType} />
      <DetailItem icon={<FiInfo />} label="Contract No" value={userDetails.contractNo} />
      <DetailItem icon={<FiInfo />} label="Contract Name" value={userDetails.contractName} />
    </DetailSection>

    <DetailSection title="Contact Information">
      <DetailItem icon={<FiPhone />} label="Mobile No" value={userDetails.mobileNo} />
      <DetailItem icon={<FiPhone />} label="Alternate Mobile No" value={userDetails.alternateMobileNo || 'N/A'} />
      <DetailItem icon={<FiMapPin />} label="City" value={userDetails.city} />
    </DetailSection>

    <DetailSection title="Personal Information">
      <DetailItem icon={<FiUser />} label="Gender" value={userDetails.gender} />
      <DetailItem icon={<FiCalendar />} label="Date of Birth" value={userDetails.dateOfBirth} />
      <DetailItem icon={<FiInfo />} label="Chronic Conditions" value={userDetails.chronicConditions} />
    </DetailSection>

    <DetailSection title="Policy Information">
      <DetailItem icon={<FiCalendar />} label="Policy Start Date" value={userDetails.policyStartDate} />
      <DetailItem icon={<FiCalendar />} label="Policy End Date" value={userDetails.policyEndDate} />
    </DetailSection>
  </div>
);

const DetailSection = ({ title, children }) => (
  <div className="tw-mb-4">
    <h3 className="tw-font-semibold tw-text-lg tw-mb-2 tw-text-gray-700">{title}</h3>
    {children}
  </div>
);

const DetailItem = ({ icon, label, value }) => (
  <div className="tw-flex tw-items-center tw-mb-2">
    <div className="tw-text-blue-500 tw-mr-2">{icon}</div>
    <div>
      <p className="tw-text-sm tw-font-medium tw-text-gray-600">{label}</p>
      <p className="tw-text-sm tw-text-gray-800">{value}</p>
    </div>
  </div>
);

export default UserDetails;