import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const StatusBadge = ({ status }) => {
    const badgeStyle = {
      padding: '4px 12px',
      borderRadius: '12px',
      fontSize: '12px',
      fontWeight: '500',
      textTransform: 'capitalize',
    };
  
    const approvedStyle = {
      ...badgeStyle,
      backgroundColor: '#E8F5E9',
      color: '#4CAF50',
    };
  
    const draftStyle = {
      ...badgeStyle,
      backgroundColor: '#FAA0A0',
      color: '#FF3131',
    };
  
    return (
      <span style={status.toLowerCase() === 'approved' ? approvedStyle : draftStyle}>
        {status}
      </span>
    );
  };

function WAActive() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [selectedLanguage, setSelectedLanguage] = useState('All');

    const columns = [
      {
        name: "Template Name",
        selector: (row) => row.templateName,
        sortable: true,
      },
      {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
        cell: (row) => <StatusBadge status={row.status} />,
      },
      {
        name: "Category",
        selector: (row) => row.category,
        sortable: true,
      },
      {
        name: "Language(s)",
        selector: (row) => row.language,
        sortable: true,
      },
      {
        name: "Created By",
        selector: (row) => row.createdBy,
        sortable: true,
      },
      {
        name: "Action",
        cell: () => (
          <div className="d-flex gap-2">
            <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
            <button className="btn btn-sm btn-outline-dark">
              <i class="bi bi-eye"></i>
            </button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
            <button className="btn btn-sm btn-outline-dark">
              <i class="bi bi-copy"></i>
            </button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
            <button className="btn btn-sm btn-outline-dark">
              <i class="bi bi-trash3"></i>
            </button>
            </OverlayTrigger>
          </div>
        ),
        ignoreRowClick: true,
      },
    ];

    const data = [
        {
            id: 1,
            templateName: 'Lorem ipsum dolor sit amet. 1',
            status: 'Approved',
            category: 'Category 1',
            language: 'English',
            createdBy: 'Admin',
        },
        {
            id: 2,
            templateName: 'Lorem ipsum dolor sit amet. 1',
            status: 'Reject',
            category: 'Category 2',
            language: 'Arabic',
            createdBy: 'Admin',
        },
        {
            id: 3,
            templateName: 'Lorem ipsum dolor sit amet. 1',
            status: 'Approved',
            category: 'Category 2',
            language: 'Arabic',
            createdBy: 'Admin',
        },
        {
            id: 4,
            templateName: 'Lorem ipsum dolor sit amet. 1',
            status: 'Approved',
            category: 'Category 2',
            language: 'Arabic',
            createdBy: 'Admin',
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedCategory('All');
        setSelectedStatus('All');
        setSelectedLanguage('All');
    };

    const filteredData = data.filter(item => {
        const matchesSearchQuery =
            item.templateName.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesCategory = selectedCategory === 'All' || item.category === selectedCategory;
        const matchesStatus = selectedStatus === 'All' || item.status === selectedStatus;
        const matchesLanguage = selectedLanguage === 'All' || item.language === selectedLanguage;

        return matchesSearchQuery && matchesCategory && matchesStatus && matchesLanguage;
    });

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3 align-items-center'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-2 px-1'>
                        <select
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Status--</option>
                            <option value="Approved">Approved</option>
                            <option value="Reject">Reject</option>
                        </select>
                    </div>
                    <div className='col-md-2 px-1'>
                        <select
                            value={selectedCategory}
                            onChange={e => setSelectedCategory(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Category--</option>
                            <option value="Category 1">Category 1</option>
                            <option value="Category 2">Category 2</option>
                        </select>
                    </div>
                    <div className='col-md-2 px-1'>
                        <select
                            value={selectedLanguage}
                            onChange={e => setSelectedLanguage(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Language--</option>
                            <option value="English">English</option>
                            <option value="Arabic">Arabic</option>
                        </select>
                    </div>
                    
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto pe-1'>
                        <h6 className="last-sync">Latest sync on</h6>
                        <h6 className="last-sync"><span>03:55 pm, 22/07/2024</span></h6>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button className='btn btn-outline-dark'><i class="bi bi-arrow-clockwise"></i></button>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                customStyles={customStyles}
            />
        </>
    );
}

export default WAActive;
