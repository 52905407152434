import React, { useState } from 'react';
import { FiSearch, FiFilter, FiBarChart2, FiChevronLeft, FiX, FiUser, FiCheck } from 'react-icons/fi';
import { useInboxContext } from './InboxContext';

const ContactList = () => {
  const { 
    contacts, 
    setSelectedContact,
    selectedContacts,
    setSelectedContacts
  } = useInboxContext();

  const [activeMode, setActiveMode] = useState(null); // 'search', 'filter', or 'selection'
  const [currentlySelectedContact, setCurrentlySelectedContact] = useState(null);

  const toggleMode = (mode) => {
    setActiveMode(activeMode === mode ? null : mode);
  };

  const toggleSelection = (id) => {
    setSelectedContacts(prev => 
      prev.includes(id) ? prev.filter(contactId => contactId !== id) : [...prev, id]
    );
  };

  const handleContactClick = (contact) => {
    if (!activeMode || activeMode !== 'selection') {
      setSelectedContact(contact);
      setCurrentlySelectedContact(contact.id);
    }
  };

  return (
    <div className="tw-h-full tw-flex tw-flex-col tw-relative">
      <Header 
        activeMode={activeMode}
        toggleMode={toggleMode}
        selectedCount={selectedContacts.length}
      />
      <div className="tw-flex-grow tw-overflow-y-auto tw-relative">
        {activeMode === 'search' && <SearchView setActiveMode={setActiveMode} />}
        {activeMode === 'filter' && <FilterView setActiveMode={setActiveMode} />}
        <ContactItems 
          contacts={contacts} 
          selectionMode={activeMode === 'selection'}
          selectedContacts={selectedContacts}
          toggleSelection={toggleSelection}
          handleContactClick={handleContactClick}
          currentlySelectedContact={currentlySelectedContact}
        />
      </div>
    </div>
  );
};

const Header = ({ activeMode, toggleMode, selectedCount }) => {
  if (activeMode === 'selection') {
    return (
      <div className="tw-flex tw-justify-between tw-items-center tw-p-4 tw-border-b tw-bg-white tw-rounded-">
        <div className="tw-flex tw-items-center">
          <button onClick={() => toggleMode(null)} className="tw-mr-2"><FiChevronLeft /></button>
          <span>{selectedCount} selected</span>
        </div>
        <div>
          <button className="tw-mr-2"><FiUser /></button>
          <button><FiCheck /></button>
        </div>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-p-4 tw-border-b tw-bg-white">
      <div className="tw-flex tw-items-center">
        <select className="tw-border tw-mr-2 tw-px-2 tw-py-1 tw-rounded-lg">
          <option>All</option>
        </select>
        <span className="tw-bg-[#03335b] tw-text-white tw-rounded-full tw-px-2 tw-py-1 tw-text-sm">13393</span>
      </div>
      <div>
        <button onClick={() => toggleMode('search')} className="tw-mr-2"><FiSearch size={20} /></button>
        <button onClick={() => toggleMode('filter')} className="tw-mr-2"><FiFilter size={20} /></button>
        <button onClick={() => toggleMode('selection')}><FiBarChart2 size={20} /></button>
      </div>
    </div>
  );
};

const SearchView = ({ setActiveMode }) => (
  <div className="tw-absolute tw-inset-0 tw-bg-white tw-z-10">
    <div className="tw-p-4">
      <div className="tw-flex tw-items-center tw-mb-4">
        <button onClick={() => setActiveMode(null)} className="tw-mr-2"><FiChevronLeft /></button>
        <h2 className="tw-font-bold">Search Inbox</h2>
      </div>
      <div className="tw-relative">
        <input 
          type="text" 
          placeholder="Search for your message or users" 
          className="tw-w-full tw-border tw-rounded-full tw-py-2 tw-px-4 tw-pl-10"
        />
        <FiSearch className="tw-absolute tw-left-3 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-text-gray-400" size={20} />
      </div>
    </div>
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-8">
      <div className="tw-bg-gray-200 tw-rounded-full tw-p-4">
        <FiSearch size={40} className="tw-text-gray-400" />
      </div>
      <p className="tw-text-center tw-mt-4 tw-text-gray-500">
        Search for messages or users<br />with the help of their name or contact number.
      </p>
    </div>
  </div>
);

const FilterView = ({ setActiveMode }) => (
  <div className="tw-absolute tw-inset-0 tw-bg-white tw-z-10 tw-overflow-y-auto">
    <div className="tw-p-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <h2 className="tw-font-bold">Select filters</h2>
        <button onClick={() => setActiveMode(null)}><FiX size={20} /></button>
      </div>
      <div className="tw-mb-4">
        <h3 className="tw-font-semibold tw-mb-2">Chat Status</h3>
        <select className="tw-w-full tw-border tw-rounded tw-p-2">
          <option>Open</option>
        </select>
      </div>
      <div className="tw-mb-4">
        <h3 className="tw-font-semibold tw-mb-2">Labels</h3>
        <select className="tw-w-full tw-border tw-rounded tw-p-2">
          <option>All</option>
        </select>
      </div>
      <div className="tw-mb-4">
        <h3 className="tw-font-semibold tw-mb-2">Assignee</h3>
        <select className="tw-w-full tw-border tw-rounded tw-p-2">
          <option>All</option>
        </select>
      </div>
      <div className="tw-mb-4">
        <h3 className="tw-font-semibold tw-mb-2">Sort</h3>
        <select className="tw-w-full tw-border tw-rounded tw-p-2">
          <option>Newest</option>
        </select>
      </div>
      <div className="tw-flex tw-justify-between">
        <button className="tw-text-blue-500">Reset</button>
        <button className="tw-bg-green-500 tw-text-white tw-px-4 tw-py-2 tw-rounded">Apply Filters</button>
      </div>
    </div>
  </div>
);

const ContactItems = ({ contacts, selectionMode, selectedContacts, toggleSelection, handleContactClick, currentlySelectedContact }) => (
  <div className="tw-overflow-y-auto">
    {contacts.map(contact => (
      <div 
      key={contact.id} 
      className={`tw-flex tw-transition-all tw-duration-300 tw-items-center tw-p-4 tw-border-b tw-cursor-pointer 
        ${selectionMode && selectedContacts.includes(contact.id) ? 'tw-bg-blue-300' : ''}
        ${!selectionMode && currentlySelectedContact === contact.id ? 'tw-bg-blue-100' : ''}
      `}
      onClick={() => handleContactClick(contact)}
    >
        {selectionMode && (
          <input 
            type="checkbox" 
            checked={selectedContacts.includes(contact.id)}
            onChange={() => toggleSelection(contact.id)}
            className="tw-mr-3 tw-h-5 tw-w-5"
          />
        )}
        {!selectionMode && (
          <div className="tw-w-10 tw-h-10 tw-bg-[#03335b] tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mr-3">
            N
          </div>
        )}
        <div className="tw-flex-grow">
          <div className="tw-flex tw-justify-between">
            <span className="tw-font-semibold">{contact.number}</span>
            <span className="tw-text-sm tw-text-gray-500">{contact.time}</span>
          </div>
          <div className="tw-flex tw-items-center">
            <span className="tw-text-green-500 tw-mr-1">●</span>
            <span className="tw-text-sm tw-text-gray-500">{contact.message}</span>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default ContactList;