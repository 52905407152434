import React, { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./CreateNewTemplate.css";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import quillEmoji from "react-quill-emoji";
import "react-quill-emoji/dist/quill-emoji.css";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";

const customStyles = `
  .custom-tooltip-wide {
    max-width: 500px !important;
  }
  .custom-tooltip-wide .tooltip-inner {
    max-width: 600px !important;
  }
`;

const TooltipContent = () => (
  <div className="custom-tooltip p-1" style={{ maxWidth: "600px !important" }}>
    <InfoItem
      textColor="text-info"
      iconClass="bi-chat-left-dots"
      bgColor="bg-info"
      text="A template can have a max of 10 Quick Reply buttons."
    />
    <InfoItem
      textColor="text-danger"
      iconClass="bi-telephone"
      bgColor="bg-danger"
      text="A template can have a max of 1 number per template."
    />
    <InfoItem
      textColor="text-warning"
      iconClass="bi-globe2"
      bgColor="bg-warning"
      text="A template can have a max of 2 URL buttons per template."
    />
  </div>
);

const InfoItem = ({ iconClass, bgColor, text, textColor }) => (
  <div
    className={`d-flex align-items-center p-2 ${bgColor} bg-opacity-10 rounded mb-2`}
  >
    <div className={`bg-white p-3 rounded-circle me-3 ${textColor}`}>
      <i className={`bi ${iconClass}`}></i>
    </div>
    <p className="m-0 flex-grow">{text}</p>
  </div>
);

const modules = {
  toolbar: {
    container: [["bold", "italic"], ["emoji"]],
  },
  "emoji-toolbar": true,
  "emoji-textarea": true,
  "emoji-shortname": true,
};

function CreateNewTemplate() {
  const [html, setHtml] = useState("");
  const [variables, setVariables] = useState(0);
  const quillRef = useRef(null);
  const [addSample, setAddSample] = useState(false);
  const [buttonType, setButtonType] = useState("button");
  const [selectedLanguages, setSelectedLanguages] = useState(["english"]);
  const [activeTab, setActiveTab] = useState("english");
  const [sections, setSections] = useState([
    { id: "website", title: "Add Website URL" },
    { id: "phone", title: "Add Phone Number" },
    { id: "quickReplies", title: "Add Quick Replies" },
  ]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true);
  }, []);

  Quill.register(
    {
      "formats/emoji": quillEmoji.EmojiBlot,
      "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
      "modules/emoji-textarea": quillEmoji.TextAreaEmoji,
      "modules/emoji-shortname": quillEmoji.ShortNameEmoji,
    },
    true
  );

  const handleSkip = () => {
    setShowModal(false);
  };

  const handleWatch = () => {
    // Implement the watch functionality here
    console.log("Watch button clicked");
    // For now, we'll just close the modal
    setShowModal(false);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    const dragIndex = Number(e.dataTransfer.getData("text/plain"));
    const newSections = [...sections];
    const [reorderedItem] = newSections.splice(dragIndex, 1);
    newSections.splice(dropIndex, 0, reorderedItem);
    setSections(newSections);
  };

  const [collapsedSections, setCollapsedSections] = useState({});
  const [websiteUrls, setWebsiteUrls] = useState([{ url: '', buttonText: '' }]);
  const [quickReplies, setQuickReplies] = useState(['']);

  const toggleSection = (sectionId) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  const addWebsiteUrl = () => {
    if (websiteUrls.length < 2) {
      setWebsiteUrls([...websiteUrls, { url: '', buttonText: '' }]);
    }
  };

  const removeWebsiteUrl = (index) => {
    const newUrls = websiteUrls.filter((_, i) => i !== index);
    setWebsiteUrls(newUrls);
  };

  const addQuickReply = () => {
    if (quickReplies.length < 10) {
      setQuickReplies([...quickReplies, '']);
    }
  };

  const removeQuickReply = (index) => {
    const newReplies = quickReplies.filter((_, i) => i !== index);
    setQuickReplies(newReplies);
  };

  const renderSection = (section, index) => {
    const isCollapsed = collapsedSections[section.id];

    const commonHeader = (
      <div
        className="mb-2"
        onClick={() => toggleSection(section.id)}
        style={{ cursor: "pointer" }}
      >
        <div className="tw-flex tw-flex-row tw-justify-start gap-2 tw-items-center">
          <i className="bi bi-grip-vertical tw-cursor-pointer tw-mt-1"></i>
          <input
            className="form-check-input form-check-input-ui"
            type="checkbox"
            name={`Add${section.id}`}
            id={`flexCheck${section.id}`}
            onClick={(e) => e.stopPropagation()}
          />
          <label
            className="form-check-label mt-1"
            htmlFor={`flexCheck${section.id}`}
          >
            {section.title}
          </label>
          <i
            className={`bi bi-chevron-${isCollapsed ? "down" : "up"} ms-auto`}
          ></i>
        </div>
      </div>
    );

    const contentClass = `row align-items-center ${
      isCollapsed ? "d-none" : ""
    }`;

    switch (section.id) {
      case "website":
        return (
          <div
            className="col-12 mb-4"
            key={section.id}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
          >
            {commonHeader}
            <div className={contentClass}>
              {websiteUrls.map((url, urlIndex) => (
                <React.Fragment key={urlIndex}>
                  <div className="col-3">
                    <select
                      className="form-select input-field-ui"
                      id=""
                      name=""
                      aria-label="Select Month"
                    >
                      <option value="">Static</option>
                      <option>1</option>
                      <option>2</option>
                    </select>
                  </div>
                  <div className="col-8 px-0">
                    <input
                      type="text"
                      className="form-control input-field-ui"
                      placeholder="Enter URL, (Eg: https://example.com/test)"
                      value={url.url}
                      onChange={(e) => {
                        const newUrls = [...websiteUrls];
                        newUrls[urlIndex].url = e.target.value;
                        setWebsiteUrls(newUrls);
                      }}
                    />
                  </div>
                  <div className="col-1">
                    <button className="btn tw-text-orange-600" onClick={() => removeWebsiteUrl(urlIndex)}>
                      <i className="bx bxs-x-circle"></i>
                    </button>
                  </div>
                  <div className="col-11 pe-0 mt-2">
                    <div className="position-relative d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control input-field-ui"
                        placeholder="Enter text for the button"
                        value={url.buttonText}
                        onChange={(e) => {
                          const newUrls = [...websiteUrls];
                          newUrls[urlIndex].buttonText = e.target.value;
                          setWebsiteUrls(newUrls);
                        }}
                      />
                      <div className="limited-inputs">0/25</div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
              {websiteUrls.length < 2 && (
                <div className="col-12 mt-2">
                  <a
                    type="button"
                    className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                    onClick={addWebsiteUrl}
                  >
                    <i className="bx bxs-plus-circle"></i> Add another website URL
                  </a>
                </div>
              )}
            </div>
          </div>
        );
      case "quickReplies":
        return (
          <div
            className="col-12 mb-4"
            key={section.id}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
          >
            {commonHeader}
            <div className={contentClass}>
              {quickReplies.map((reply, replyIndex) => (
                <React.Fragment key={replyIndex}>
                  <div className="col-11 pe-0">
                    <div className="position-relative d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control input-field-ui"
                        placeholder="Enter text for the button"
                        value={reply}
                        onChange={(e) => {
                          const newReplies = [...quickReplies];
                          newReplies[replyIndex] = e.target.value;
                          setQuickReplies(newReplies);
                        }}
                      />
                      <div className="limited-inputs">0/25</div>
                    </div>
                  </div>
                  <div className="col-1">
                    <button className="btn tw-text-orange-600" onClick={() => removeQuickReply(replyIndex)}>
                      <i className="bx bxs-x-circle"></i>
                    </button>
                  </div>
                </React.Fragment>
              ))}
              {quickReplies.length < 10 && (
                <div className="col-12 mt-2">
                  <a
                    type="button"
                    className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                    onClick={addQuickReply}
                  >
                    <i className="bx bxs-plus-circle"></i> Add another Quick Reply
                  </a>
                </div>
              )}
            </div>
          </div>
        );
        case "phone":
          return (
            <div
              className="col-12 mb-4"
              key={section.id}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, index)}
            >
              {commonHeader}
              <div className={contentClass}>
                <div className="col-3">
                  <select
                    className="form-select input-field-ui"
                    id=""
                    name=""
                    aria-label="Select Month"
                  >
                    <option value="">Static</option>
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
                <div className="col-8 px-0">
                  <div className="position-relative d-flex align-items-center">
                    <input
                      type="text"
                      className="form-control input-field-ui"
                      id="templateName"
                      name="templateName"
                      placeholder="Enter Phone Number"
                    />
                    <div className="limited-inputs">0/25</div>
                  </div>
                </div>
                <div className="col-11 pe-0 mt-2">
                  <div className="position-relative d-flex align-items-center">
                    <input
                      type="text"
                      className="form-control input-field-ui"
                      id="templateName"
                      name="templateName"
                      placeholder="Enter text for the button"
                    />
                    <div className="limited-inputs">0/25</div>
                  </div>
                </div>
              </div>
            </div>
          );
      default:
        return null;
    }
  };

  const handleLanguageChange = (event) => {
    const language = event.target.value;
    if (language && !selectedLanguages.includes(language)) {
      const newSelectedLanguages = [...selectedLanguages, language];
      setSelectedLanguages(newSelectedLanguages);
      setActiveTab(language);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleRemoveTab = (langToRemove) => {
    const updatedLanguages = selectedLanguages.filter(
      (lang) => lang !== langToRemove
    );
    setSelectedLanguages(updatedLanguages);
    if (activeTab === langToRemove) {
      setActiveTab(updatedLanguages[0] || "");
    }
  };

  useEffect(() => {
    if (html === '') {
      setVariables(0);
    }
  }, [html]);

  const onChange = (content) => {
    setHtml(content);
    if (content === '<p><br></p>' || content === '') {
      setVariables(0);
    }
  };

  const handleAddVariable = () => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection(true);
      const position = range ? range.index : quill.getLength();
      
      const newVariableCount = variables + 1;
      setVariables(newVariableCount);
      
      quill.insertText(position, `{{${newVariableCount}}}`, 'user');
      quill.setSelection(position + `{{${newVariableCount}}}`.length);
    }
  };

  const editorStyle = {
    height: "160px",
  };

  const editorWrapperStyle = {
    height: "160px",
    marginBottom: "0px",
  };

  const handleBackClick = () => {
    window.history.back();
  };

  const [selectedTemplate, setSelectedTemplate] = useState("None");

  const handleRadioChange = (event) => {
    setSelectedTemplate(event.target.value);
  };

  // Header Image Upload
  const [fileName, setFileName] = useState("Choose JPG or PNG file");
  const [imagePreview, setImagePreview] = useState(null);

  const handleFileChange = (event) => {
    const { files } = event.target;
    if (files.length > 0) {
      const file = files[0];
      setFileName(file.name);

      // Create a FileReader to read the file
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFileName("Choose JPG or PNG file");
      setImagePreview(null);
    }
  };

  // Header Video Upload
  const [videoName, setVideoName] = useState("Choose MP4 file");
  const [videoPreview, setVideoPreview] = useState(null);

  const handleVideoChange = (event) => {
    const { files } = event.target;
    if (files.length > 0) {
      const file = files[0];
      setVideoName(file.name);

      // Create a VideoReader to read the file
      const reader = new FileReader();
      reader.onloadend = () => {
        setVideoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setVideoName("Choose MP4 file");
      setVideoPreview(null);
    }
  };

  // Header Document Upload
  const [documentName, setDocumentName] = useState("Choose PDF file");
  const [documentPreview, setDocumentPreview] = useState(null);

  const handleDocumentChange = (event) => {
    const { files } = event.target;
    if (files.length > 0) {
      const file = files[0];
      setDocumentName(file.name);

      // Create a VideoReader to read the file
      const reader = new FileReader();
      reader.onloadend = () => {
        setDocumentPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setDocumentName("Choose PDF file");
      setDocumentPreview(null);
    }
  };

  return (
    <>
      <section className="CreateNewTemplate ">
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div
              className="col-md"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-primary back-btn mb-0 me-3"
                onClick={handleBackClick}
              >
                <i className="bi bi-chevron-left me-1"></i> Back
              </button>
              <h1 className="main-title me-3" style={{ margin: "0" }}>
                Create New Template
              </h1>
              {!showModal && (
                <h1 className="tw-bg-sky-200 tw-font-bold tw-border tw-border-sky-700 tw-rounded-xl tw-px-2 tw-py-1 tw-text-[#03355b]">
                  Need help getting started? Watch{" "}
                  <span
                    onClick={() => setShowModal(true)}
                    className="text-primary tw-cursor-pointer"
                  >
                    Best Practices to create Whatsapp Template
                  </span>
                </h1>
              )}
            </div>
          </div>

          <div className="create-new-template p-4">
            <div className="row align-items-center mb-3">
              <div className="col-md-4 mb-3">
                <label htmlFor="templateName" className="form-label">
                  Template Name
                </label>
                <input
                  type="text"
                  className="form-control input-field-ui"
                  id="templateName"
                  name="templateName"
                  placeholder="Enter Template Name..."
                />
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="templateCategory" className="form-label">
                  Category
                </label>
                <select
                  className="form-select input-field-ui"
                  id="conditionId"
                  name="selectMonth"
                  aria-label="Select Month"
                >
                  <option value="">Choose Category</option>
                  <option>Marketing</option>
                  <option>Utility</option>
                  <option>Authentication</option>
                </select>
              </div>

              {/* <div className="col-md-4 mb-3">
                            <label htmlFor="templateCategory" className="form-label">Button Type(Optional)</label>
                            <select className="form-select input-field-ui" id="conditionId" name="selectMonth" aria-label="Select Month">
                                <option value="">None</option>
                                <option>1</option>
                                <option>2 </option>
                            </select>
                        </div> */}
            </div>

            <div className="row align-items-center mb-3">
              <div className="col-12">
                <h4 className="mb-0 add-new-template-title">Select Language</h4>
              </div>
            </div>
            <div className="row align-items-center mb-3">
              {/* <div className="col-auto">
                <a
                  type="button"
                  className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                >
                  English
                </a>
              </div> */}
              <div className="col-2">
                <select
                  className="form-select input-field-ui add-language"
                  id=""
                  value=""
                  name="selectMonth"
                  aria-label="Select Month"
                  onChange={handleLanguageChange}
                >
                  <option value="">+ Add Language</option>
                  <option value="english">English</option>
                  <option value="arabic">Arabic</option>
                </select>
              </div>
            </div>

            {selectedLanguages.length > 0 && (
              <div>
                <ul className="nav nav-tabs">
                  {selectedLanguages.map((lang) => (
                    <li className="nav-item" key={lang}>
                      <button
                        className={`nav-link ${
                          activeTab === lang ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(lang)}
                      >
                        {lang.charAt(0).toUpperCase() + lang.slice(1)}

                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveTab(lang);
                          }}
                          className=" tw-text-orange-600 tw-ml-2 tw-pb-0 tw-cursor-pointer"
                        >
                          <i className="bx bxs-x-circle"></i>
                        </button>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="row mt-4 mb-3">
              <div className="col-md-8">
                <div className="row align-items-center">
                  <div className="col-12 mb-4">
                    {activeTab === "english" && (
                      <h4 className="mb-0 add-new-template-subtitle">
                        Template for English UK Language
                      </h4>
                    )}
                    {activeTab === "arabic" && (
                      <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
                        <h4 className="mb-0 add-new-template-subtitle">
                          Template for Arabic Language
                        </h4>
                        <button onClick={() => setAddSample(true)} className="btn btn-success">Add Sample</button>
                      </div>
                    )}
                  </div>

                  <div className="col-12 mb-2">
                    <h4 className="mb-0 add-new-template-subtitle1">
                      Header (Optional)
                    </h4>
                  </div>
                  <div className="col-12">
                    {" "}
                    <label className="form-check-label pt-1">
                      Add a title, or, select the media type you want to get
                      approved for this template's header
                    </label>
                  </div>

                  <div className="col-12 mt-2">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        name="templateRadio"
                        id="templateRadio1"
                        type="radio"
                        value="None"
                        checked={selectedTemplate === "None"}
                        onChange={handleRadioChange}
                      />
                      <label
                        htmlFor="templateRadio1"
                        className="form-check-label pt-1"
                      >
                        None
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        name="templateRadio"
                        id="templateRadio2"
                        type="radio"
                        value="Text"
                        checked={selectedTemplate === "Text"}
                        onChange={handleRadioChange}
                      />
                      <label
                        htmlFor="templateRadio2"
                        className="form-check-label pt-1"
                      >
                        Text
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        name="templateRadio"
                        id="templateRadio3"
                        type="radio"
                        value="Image"
                        checked={selectedTemplate === "Image"}
                        onChange={handleRadioChange}
                      />
                      <label
                        htmlFor="templateRadio3"
                        className="form-check-label pt-1"
                      >
                        Image
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        name="templateRadio"
                        id="templateRadio4"
                        type="radio"
                        value="Video"
                        checked={selectedTemplate === "Video"}
                        onChange={handleRadioChange}
                      />
                      <label
                        htmlFor="templateRadio4"
                        className="form-check-label pt-1"
                      >
                        Video
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        name="templateRadio"
                        id="templateRadio5"
                        type="radio"
                        value="Document"
                        checked={selectedTemplate === "Document"}
                        onChange={handleRadioChange}
                      />
                      <label
                        htmlFor="templateRadio5"
                        className="form-check-label pt-1"
                      >
                        Document
                      </label>
                    </div>
                  </div>

                  <div className="col-12 mt-2">
                    {selectedTemplate === "None" && <div id="noneHeader"></div>}

                    {selectedTemplate === "Text" && (
                      <div id="textHeader" className="row">
                        <div className="col-12 mb-2">
                          <div className="position-relative d-flex align-items-center">
                            <input
                              type="text"
                              class="form-control input-field-ui"
                              placeholder="Enter text"
                            />
                            <div className="limited-inputs">0/60</div>
                          </div>
                        </div>
                        <div className="col-12">
                          <a
                            type="button"
                            className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                            
                          >
                            <i className="bx bxs-plus-circle"></i> Add variable
                          </a>
                        </div>
                      </div>
                    )}

                    {selectedTemplate === "Image" && (
                      <div id="imageHeader" className="row">
                        <div className="col-12 mb-2">
                          <div className="d-flex align-items-center">
                            <div className="file-input-container">
                              <label
                                htmlFor="fileInput"
                                className="custom-file-label"
                              >
                                {fileName}
                              </label>
                              <input
                                type="file"
                                id="fileInput"
                                className="custom-file-input"
                                accept=".jpg, .png"
                                onChange={handleFileChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <label className="form-check-label pt-1">
                            This media file will be sent as a sample to
                            WhatsApp, for approval. At the time of sending the
                            template, you can change the media file, if
                            required. You can also send separate media files for
                            each customer in a campaign.
                          </label>
                        </div>

                        {imagePreview && (
                          <div className="col-12 mb-2 mt-3">
                            <div className="row">
                              <div className="col-auto">
                                <div className="image-preview">
                                  <img src={imagePreview} alt="Preview" />
                                </div>
                              </div>
                              <div className="col px-0">
                                <button className="btn delete-preview-btn">
                                  <i class="bi bi-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {selectedTemplate === "Video" && (
                      <div id="videoHeader">
                        <div className="col-12 mb-2">
                          <div className="d-flex align-items-center">
                            <div className="file-input-container">
                              <label
                                htmlFor="videoInput"
                                className="custom-file-label"
                              >
                                {videoName}
                              </label>
                              <input
                                type="file"
                                id="videoInput"
                                className="custom-file-input"
                                accept=""
                                onChange={handleVideoChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <label className="form-check-label pt-1">
                            This media file will be sent as a sample to
                            WhatsApp, for approval. At the time of sending the
                            template, you can change the media file, if
                            required. You can also send separate media files for
                            each customer in a campaign.
                          </label>
                        </div>

                        {videoPreview && (
                          <div className="col-12 mb-2 mt-3">
                            <div className="row">
                              <div className="col-auto">
                                <div className="image-preview">
                                  <img src={videoPreview} alt="Preview" />
                                </div>
                              </div>
                              <div className="col px-0">
                                <button className="btn delete-preview-btn">
                                  <i class="bi bi-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {selectedTemplate === "Document" && (
                      <div id="documentHeader">
                        <div className="col-12 mb-2">
                          <div className="d-flex align-items-center">
                            <div className="file-input-container">
                              <label
                                htmlFor="documentInput"
                                className="custom-file-label"
                              >
                                {documentName}
                              </label>
                              <input
                                type="file"
                                id="documentInput"
                                className="custom-file-input"
                                accept=".pdf"
                                onChange={handleDocumentChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <label className="form-check-label pt-1">
                            This media file will be sent as a sample to
                            WhatsApp, for approval. At the time of sending the
                            template, you can change the media file, if
                            required. You can also send separate media files for
                            each customer in a campaign.
                          </label>
                        </div>

                        {documentPreview && (
                          <div className="col-12 mb-2 mt-3">
                            <div className="row">
                              <div className="col-auto">
                                <div className="image-preview">
                                  <img src={documentPreview} alt="Preview" />
                                </div>
                              </div>
                              <div className="col px-0">
                                <button className="btn delete-preview-btn">
                                  <i class="bi bi-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row align-items-center mt-4">
                  <div className="col-12 mb-2">
                    <h4 className="mb-0 add-new-template-subtitle1">Body</h4>
                  </div>
                  <div className="col-12 mb-3">
                    <label className="form-check-label pt-1">
                      The Whatsapp message in the language you have selected{" "}
                    </label>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div
                      style={editorWrapperStyle}
                      className="position-relative"
                    >
                      <ReactQuill
                      ref={quillRef}
                        value={html}
                        onChange={onChange}
                        modules={{
                          toolbar: {
                            container: [
                              ["bold", "italic"],
                              ["emoji"],
                              ["clean"],
                            ],
                          },
                          "emoji-toolbar": true,
                          "emoji-textarea": true,
                          "emoji-shortname": true,
                        }}
                        className="form-control input-field-ui p-0"
                        placeholder="Text"
                        style={editorStyle}
                      />
                      <div className="limited-inputs1">0/1000</div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <a
                      type="button"
                      className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                      onClick={(e) => {
                        e.preventDefault();
                        handleAddVariable();
                      }}
                    >
                      <i className="bx bxs-plus-circle"></i> Add variable
                    </a>
                  </div>
                </div>
                <div className="col-md-8 tw-flex tw-flex-col tw-gap-1 mb-3">
                  <h4 className="tw-font-semibold">Footer (Optional)</h4>
                  <p>
                    Add a short line of text to the bottom of your message
                    template.
                  </p>
                  <div className="tw-relative">
                    <input
                      type="text"
                      className="form-control input-field-ui"
                      id=""
                    />
                    <div className="tw-absolute tw-top-3 tw-h-full tw-right-2 tw-text-gray-400">
                      0/60
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <label htmlFor="templateCategory" className="form-label">
                    Button Type(Optional)
                  </label>
                  <select
                    className="form-select input-field-ui"
                    id="conditionId"
                    name="selectMonth"
                    aria-label="Select Month"
                    onChange={(e) => setButtonType(e.target.value)}
                  >
                    <option value="none">None</option>
                    <option value="url">Quick Replies, URL etc.</option>
                    {/* <option>2 </option> */}
                  </select>
                </div>

                {buttonType === "url" && (
                  <>
                    <div className="row align-items-center mt-4">
                      <div className="col-12 mb-2">
                        <h4 className="mb-0 add-new-template-subtitle1">
                          Copy code, URL, Quick Replies etc
                        </h4>
                      </div>
                      <div className="col-12 mb-3">
                        <label className="form-check-label pt-1">
                          Create buttons that let customers respond to your
                          message or take action.
                        </label>
                      </div>

                      <div className="col-md-12 mb-3">
                        <div className="copy-code-box">
                          <div className="row">
                            <div className="col-12 mb-4">
                              <div className="copy-code-alt-box">
                                <h4 className="mb-0 copy-code-title">
                                  The total number of buttons from all four
                                  types cannot exceed 10.{" "}
                                  <style>{customStyles}</style>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={(props) => (
                                      <Tooltip
                                        id="tooltip-top"
                                        className="custom-tooltip-wide"
                                        {...props}
                                      >
                                        <TooltipContent />
                                      </Tooltip>
                                    )}
                                  >
                                    <a
                                      type="button"
                                      className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                                    >
                                      Learn More
                                    </a>
                                  </OverlayTrigger>
                                </h4>

                                <h4 className="mb-0 copy-code-title">3/10</h4>
                              </div>
                            </div>

                            {sections.map((section, index) =>
                              renderSection(section, index)
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-4">
                <div className="row align-items-center">
                  <div className="col-12 mb-2">
                    <h4 className="mb-0 add-new-template-subtitle text-center">
                      Preview
                    </h4>
                  </div>
                  <div className="col-12">
                    <div className="iphone">
                      <div className="border">
                        <div className="quate-bg">
                          <div className="row">
                            <div className="col-12">
                              <h2 className="quate-title">
                                Welcome, [Member's First Name]! You're now
                                enrolled in [Program/Service Name]. Get ready to
                                achieve your health goals with our support.
                                We'll be in touch with more details soon. Any
                                questions? Contact us at [Contact Number].s
                              </h2>
                              <h3 className="quate-subtitle">#BeTheForce</h3>
                              <h3 className="quate-time">02:23 Pm</h3>
                            </div>

                            <div className="col-12 text-center px-1">
                              <hr className="my-2" />
                              <a
                                type="button"
                                className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                              >
                                <i className="bx bx-link-external"></i> Download
                                App Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-12 text-center">
                <button
                  type="submit"
                  className="btn btn-primary btn-primary program-save-btn me-2"
                >
                  Submit
                </button>
                <button type="button" className="btn clear-btn">
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal show={showModal} onHide={handleSkip} centered>
        <Modal.Header closeButton>
          <Modal.Title>Need help to get started?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center ">
            <h1 className="mb-3">
              Watch <span>Best Practices to create Whatsapp Template</span>
            </h1>
            <div className="d-flex justify-content-center gap-3">
              <Button
                style={{
                  backgroundColor: "#03335b",
                  color: "#fff",
                  borderRadius: "40px",
                }}
                onClick={handleWatch}
              >
                Watch
              </Button>
              <Button
                style={{
                  backgroundColor: "#fff",
                  color: "#03335b",
                  fontWeight: "700",
                  border: "1px solid #03335b",
                  borderRadius: "40px",
                }}
                onClick={handleSkip}
              >
                Skip
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={addSample} onHide={() => setAddSample(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Sample Content (Arabic)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center ">
            <h1 className="mb-3">
             To help WhatsApp understand what kind of message you want to send, you have the option to provide specific examples of the variables in your templates by adding samples for them here. In case you have a media header in your template, you can also provide a sample file for the media header.
            </h1>
            <div className="d-flex justify-content-center gap-3">
             
              <Button
                style={{
                  backgroundColor: "#fff",
                  color: "#03335b",
                  fontWeight: "700",
                  border: "1px solid #03335b",
                  borderRadius: "40px",
                }}
                onClick={() => setAddSample(false)}
              >
                Done
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateNewTemplate;
